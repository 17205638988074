import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import { v4 as uuid } from 'uuid';
import gpeapi from '../api/gpeapi';
import Home from './Home';

const Shareoffer = () => {
    const { offerid, userid, token } = useParams()

    const clkRef = useRef(null)
    const [data, setdata] = useState()

    const [clickid, setclickid] = useState('')
    const [loading,setloading]=useState(false)

    const [txndta,settxndata] = useState()



    useEffect(() => {
        setclickid(uuid())
        calltofetchOffer()
         callforRedirection()
    }, [data?.success])

    const calltofetchOffer = async () => {

        setloading(true)

        const myrss = await gpeapi.get(`/sharelinkchecktxn/?offerid=${offerid}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then(async(ress)=>{
            settxndata(ress.data)

            if(!ress?.data?.allCheck){
                if(!ress?.data?.todayCheck){
                    const res = await gpeapi.get(`/getoffer/${offerid}`, {
                        headers: {
                            "Authorization": `Beaer ${token}`
                        }
                    }).then(async(ress)=>{
                        setdata(ress.data)
            
            
                    }).catch((e)=>{
                        setdata({success:false})
                    })
                }
            }

            


        }).catch((e)=>{
            settxndata({success:false})
        })

        

        
        setloading(false)
    }

    const callforRedirection = async () => {
       if(data?.success){
        if (data?.mydta?.shareandearn) {


            const bodydata = {
                clickid: clickid,
                userid: userid,
                from: 'Share & Earn',
                storename: data?.mydta?.name
            }

            if (data?.mydta?.isactive) {
                const res = await gpeapi.post(`/saveclick`, bodydata, {
                    headers: {
                        "Authorization": `Beaer ${token}`
                    }
                }).then(async () => {

                    setTimeout(() => {
                        hndleclk()
                    }, 1000);
                    // clkRef.current.click()



                })
            }
        } else {
            console.log('nott')
        }
       }

    }

    const hndleclk = async () => {
        const urlad = await data?.mydta?.url
        const ress = await urlad.replace('{USERID}', userid)
        const clickidurl = await ress.replace('{CLICKID}', clickid)
        // window.open(clickidurl)
        // console.log(clickidurl)
        window.location.href = clickidurl
    }



    return (
        <div>
            {
                loading?(
                    <img  src='https://miro.medium.com/v2/resize:fit:1400/1*CsJ05WEGfunYMLGfsT2sXA.gif'/>

                ):(
                    <>
                    {
                            
                            txndta?.allCheck? <p>Limit Reached</p> : txndta?.todayCheck? <p>Today Limit Reached</p>: data?.mydta == null ? (
                            <>
                            <Home/>
                            <p>This offer Not Avaliable</p>
                            </>
                        ) : data?.mydta?.isactive ? (
                            <p>Redirecting to Offer</p>
        
                        ) : (
                            <p>This offer is Currently in-Active</p>
        
                        )
                    }
                    </>
                )
            }




            

            {/* <button onClick={hndleclk}>clk</button> */}
            {/* <p>{data?.mydta==null && 'This Offer Not Avalible'}</p> */}
        </div>
    )
}

export default Shareoffer