import React from 'react'

const Home = () => {

    const handleopenapp = ()=>{
        // window.open('https://play.google.com/store/apps/details?id=com.gwalaxpe&hl=en&gl=US')
        window.location.href = 'https://play.google.com/store/apps/details?id=com.gwalaxpe&hl=en&gl=US'
    }
  return (
    <div>
        <img src='https://play-lh.googleusercontent.com/-8VLwXLrulsvwaSfN8PdE37NrtFXBSoR9OcWx8D9pLipZ6Ife7lyqUGp8qRvYZGLug=w240-h480-rw'/>
        <p style={{fontSize:15}}>Download GwalaxPe App and Earn Money</p>
        <button onClick={handleopenapp} style={{margin:10,padding:10,backgroundColor:'#281845',border:'none',fontSize:25,color:'white',borderRadius:5,cursor:'pointer'}}>Download Now</button>
    </div>
  )
}

export default Home