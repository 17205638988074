import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes, Route
} from "react-router-dom";
import Home from './Pages/Home';
import Shareoffer from './Pages/Shareoffer';
import { useEffect } from 'react';
import disableDevtool from'disable-devtool';

function App() {

  useEffect(()=>{
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    disableDevtool();
  },[])
  return (
    <div className="App">
     <Router>
      <Routes>
        <Route Component={Home} path='/'/>
        <Route Component={Home} path='*'/>

        <Route Component={Shareoffer} path='/redirect/:token/:offerid/:userid'/>
      </Routes>
     </Router>
    </div>
  );
}

export default App;
